<template>
  <div>
    <b-row v-if="options.length > 0">
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="options"
            :fields="fields"
            @row-dblclicked="editForm"
            @row-clicked="clicked"
            head-row-variant="secondary"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(is_active)="data">
              <div v-if="data.item.is_active === 1">Активен</div>
              <div v-else>Не активен</div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
export default {
  props: ["options"],
  data() {
    return {
      fields: [
        { key: "checkbox", label: "Флаг" },
        { key: "id", label: "ID", sortable: true },
        { key: "name", label: "Имя", sortable: true },
        { key: "type", label: "Тип", sortable: true },
        { key: "created_by", label: "Сделано", sortable: true },
        { key: "is_active", label: "Статус", sortable: true },
        { key: "created_at", label: "Создан", sortable: true },
      ],
    };
  },
  mounted() {
    resizeable();
  },
  methods: {
    editForm(id) {
      this.$emit("editForm", id);
    },
    clicked(item, index, event) {
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }

      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;
      this.$store.state.filterPlus.filterPlus = event.target.innerHTML.trim();
      cellSelect();
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/table.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
